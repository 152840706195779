import { render, staticRenderFns } from "./email-unverified-page.vue?vue&type=template&id=baf03b60&scoped=true&"
import script from "./email-unverified-page.vue?vue&type=script&lang=js&"
export * from "./email-unverified-page.vue?vue&type=script&lang=js&"
import style0 from "./email-unverified-page.vue?vue&type=style&index=0&id=baf03b60&scoped=true&lang=css&"
import style1 from "./email-unverified-page.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baf03b60",
  null
  
)

export default component.exports
import {QImg,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg,QBtn})
