//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';

const { fields } = UserModel;

export default {
  name: 'app-email-unverified-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
    };
  },

  computed: {

    // mapGetters to return loading  and email 

    ...mapGetters({
      loading: 'auth/loadingEmailConfirmation',
      email: 'auth/currentUserEmail',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),

    fields() {
      return fields;
    },

     // this computed for enableRtl

    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar'
    },
  },

  methods: {

    // mapAction call three  functions from store { doSendEmailConfirmation && doSignout && doEmailVerified }

    ...mapActions({
      doSendEmailConfirmation:
        'auth/doSendEmailConfirmation',
      doSignout: 'auth/doSignout',
      doEmailVerified:'auth/doEmailVerified'
    }),

    // call function doSendEmailConfirmation and pass email taken from model to it 

    async doSubmit() {
      await this.doSendEmailConfirmation(this.model.email);
    },

   // i18n for localization 

    i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
  },
};
